import React from 'react'
import { Button } from 'react-bootstrap';
import './Challenge.css'
import './../../App.css';
import { FaArrowRightLong } from "react-icons/fa6";

function Challenge() {
  return (
    <div className='container-fluid bg-challenge'>
        <div className='container'>
            <div className='container text-center py-5 challenge-padding'>
              <h2>Your challenges. Our Solutions.</h2>
              <p>we design innovative and scalable web solutions that prioritize user experience and adaptability. Our future-focused software empowers businesses to thrive in the fast-changing digital world, ensuring their success and growth through seamless, user-centered products.</p>
              <button className='btn-design'>Explore All Solutions <FaArrowRightLong /></button> 
            </div>           
        </div>
    </div>
  )
}

export default Challenge