import React from 'react';
import './SelectCategory.css';
import all from './../../../assets/web-commerce-img/all.png';
import ecommerce from './../../../assets/web-commerce-img/exommerce.png';
import grocery from './../../../assets/web-commerce-img/grocery.png';
import portfolio from './../../../assets/web-commerce-img/portfolio.png';
import juicebar from './../../../assets/web-commerce-img/juicebar.png';
import realEstate from './../../../assets/web-commerce-img/realEstate.png';

function SelectCategory() {
  return (
    <div className='container-fluid'>
        <div className='container'>
            <p className='select-category-text'>Select Category</p>
            <div className='all-categories d-flex'>
                <div className='category-all'>
                    <img src={all} alt={all}/>
                    <p>All</p>
                </div>
                <div className='category-eCommerce'>
                    <img src={ecommerce} alt={ecommerce}/>
                    <p>E-Commerce</p>
                </div>
                <div className='category-grocery'>
                    <img src={grocery} alt={grocery}/>
                    <p>Grocery</p>
                </div>
                <div className='category-portfolio'>
                    <img src={portfolio} alt={portfolio}/>
                    <p>Portfolio</p>
                </div>
                <div className='category-juicebar'>
                    <img src={juicebar} alt={juicebar}/>
                    <p>Juice Bar</p>
                </div>
                <div className='category-realestate'>
                    <img src={realEstate} alt={realEstate}/>
                    <p>Real Estate</p>
                </div>
            </div>

            <hr/>
        </div>
    </div>
  )
}

export default SelectCategory