import React from 'react'
import './OurBrand.css';
import Peoply from './../../assets/img/Logo peoply.png';
import '../../App.css';
import { FaArrowRightLong } from "react-icons/fa6";
// import peoply1 from './../../assets/img/1.png';
import peoply2 from './../../assets/img/2.png';
import peoply3 from './../../assets/img/3.png';
import peoply4 from './../../assets/img/4.png';
import peoply5 from './../../assets/img/5.png';
import peoply6 from './../../assets/img/6.png';

function OurBrand() {
  return (
    <div className='container-fluid bg-our-brand'>
        <div className='container py-5'>
            <div className='row'>
                <div className='col-md-6'>
                    <button className='btn-design d-flex align-items-center justify-content-center'>Our Brands <FaArrowRightLong className='space-button-icon'/></button>
                </div>
                <div className='col-md-6'>
                    <p>Our vision is surving a quality product to you such as Software Engineering, Design & UX, and more,  to find where your unique talents can shine and drive impact.</p>
                </div>
            </div>



                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className='row'>
                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='peoply-bg'>
                                        <img src={peoply6} class="card-img-top  justify-content-center align-items-center" alt={peoply6}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h6 class="card-title">E-commerce</h6>
                                        {/* <p class="card-text">We make peoply for your help We make peoply for your help</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='peoply-bg'>
                                        <img src={peoply2} class="card-img-top  justify-content-center align-items-center" alt={peoply2}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h6 class="card-title">Service</h6>
                                        {/* <p class="card-text">We make peoply for your help We make peoply for your help</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='peoply-bg'>
                                        <img src={peoply3} class="card-img-top  justify-content-center align-items-center" alt={peoply3}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h6 class="card-title">Small Business</h6>
                                        {/* <p class="card-text">We make peoply for your help We make peoply for your help</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='peoply-bg'>
                                        <img src={peoply4} class="card-img-top  justify-content-center align-items-center" alt={peoply4}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h6 class="card-title">Landing Page</h6>
                                        {/* <p class="card-text">We make peoply for your help We make peoply for your help</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="carousel-item">
                        <div className='row'>
                        <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='peoply-bg'>
                                        <img src={peoply5} class="card-img-top  justify-content-center align-items-center" alt={peoply5}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h6 class="card-title">The Peoply</h6>
                                        {/* <p class="card-text">We make peoply for your help</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='peoply-bg'>
                                        <img src={peoply6} class="card-img-top  justify-content-center align-items-center" alt={peoply6}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h6 class="card-title">E-commerce</h6>
                                        {/* <p class="card-text">We make peoply for your help</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='peoply-bg'>
                                        <img src={peoply4} class="card-img-top  justify-content-center align-items-center" alt={peoply4}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h6 class="card-title">Landing Page</h6>
                                        {/* <p class="card-text">We make peoply for your help</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='peoply-bg'>
                                        <img src={peoply3} class="card-img-top  justify-content-center align-items-center" style={{"width":"100%"}} alt={peoply3}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h6 class="card-title">Small Business</h6>
                                        {/* <p class="card-text">We make peoply for your help</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
                </div>

        </div>
    </div>
  )
}

export default OurBrand