import React from 'react'
import { Card } from 'react-bootstrap';
import CarbonImg from './../../assets/img/carbon_container-software.png';
import './OurServices.css';
import '../../App.css';
import { FaArrowRightLong } from "react-icons/fa6";
import softwareDev from './../../assets/img/Software-development.jpeg';
import designUX from './../../assets/img/ui-ux.png';
import AI from './../../assets/img/ai.jpg';
import research from './../../assets/img/research.jpg';

function OurServices() {
  return (
    <div className='container-fluid bg-our-services'>
        <div className='container'>
            <div className='row py-4'>
                <div className='col-md-6 d-flex align-items-center'>
                    
                    <button className='btn-design'>Our Services <FaArrowRightLong /></button>
                </div>
                <div className='col-md-6'>
                    <p className='text-responsive-center'>We design innovative and scalable web solutions that prioritize user experience and adaptability. Our future-focused software empowers businesses to thrive in the fast-changing digital world, ensuring their success and growth through seamless, user-centered products.</p>
                </div>
            </div>

            {/* <div className='row'>
                <div className='col-md-3'>
                    <Card className='bg-our-services-card my-2'>
                        <Card.Img variant="top" src={CarbonImg} style={{"height":"100px", "width":"100px", "text-align":"center"}} className='p-3 m-auto'/>
                        <Card.Body className='text-center'>
                            <Card.Title>Software Development</Card.Title>
                            <Card.Text>
                            Dive into complex challenges with a top-tier engineering team, crafting innovative software solutions.
                            </Card.Text>
                            
                        </Card.Body>
                    </Card>
                </div>

                <div className='col-md-3'>
                    <Card className='bg-our-services-card my-2'>
                        <Card.Img variant="top" src={CarbonImg} style={{"height":"100px", "width":"100px", "text-align":"center"}} className='p-3 m-auto'/>
                        <Card.Body className='text-center'>
                            <Card.Title>Design & UX</Card.Title>
                            <Card.Text>
                            Collaborate with creative minds to design captivating user experiences and interfaces that stand out.
                            </Card.Text>
                            
                        </Card.Body>
                    </Card>
                </div>

                <div className='col-md-3'>
                    <Card className='bg-our-services-card my-2'>
                        <Card.Img variant="top" src={CarbonImg} style={{"height":"100px", "width":"100px", "text-align":"center"}} className='p-3 m-auto'/>
                        <Card.Body className='text-center'>
                            <Card.Title>Artificial Intelligence (AI)</Card.Title>
                            <Card.Text>
                            Push the boundaries of AI, developing cutting-edge applications that redefine what's possible.
                            </Card.Text>
                            
                        </Card.Body>
                    </Card>
                </div>

                
                <div className='col-md-3'>
                    <Card className='bg-our-services-card my-2'>
                        <Card.Img variant="top" src={CarbonImg} style={{"height":"100px", "width":"100px", "text-align":"center"}} className='p-3 m-auto'/>
                        <Card.Body className='text-center'>
                            <Card.Title>Research</Card.Title>
                            <Card.Text>
                            Contribute to cutting-edge research, exploring new technologies to drive innovation.
                            </Card.Text>
                            
                        </Card.Body>
                    </Card>
                </div>

            </div>    */}

            <div className='row'>
                <div className='col-md-3 card'>
                    <div className='service-image'>
                        <img src={softwareDev} alt={softwareDev} className='img-fluid serv-img'/>
                    </div>
                    <div className='service-text'>
                        <h5>Software Development
                        </h5>
                        {/* <p>Dive into complex challenges with a top-tier engineering team, crafting innovative software solutions.</p> */}
                    </div>
                </div>
                <div className='col-md-3 card'>
                    <div className='service-image'>
                        <img src={designUX} alt={designUX} className='img-fluid serv-img'/>
                    </div>
                    <div className='service-text'>
                        <h5>Design & UX
                        </h5>
                        {/* <p>Dive into complex challenges with a top-tier engineering team, crafting innovative software solutions.</p> */}
                    </div>
                </div>
                <div className='col-md-3 card'>
                    <div className='service-image'>
                        <img src={AI} alt={AI} className='img-fluid serv-img'/>
                    </div>
                    <div className='service-text'>
                        <h5>Artificial Intelligence
                        </h5>
                        {/* <p>Dive into complex challenges with a top-tier engineering team, crafting innovative software solutions.</p> */}
                    </div>
                </div>
                <div className='col-md-3 card'>
                    <div className='service-image'>
                        <img src={research} alt={research} className='img-fluid serv-img'/>
                    </div>
                    <div className='service-text'>
                        <h5>Research
                        </h5>
                        {/* <p>Dive into complex challenges with a top-tier engineering team, crafting innovative software solutions.</p> */}
                    </div>
                </div>
            </div>


        </div>
    </div>

    // <div className='container-fluid'>
    //     <div className='container'>
    //         <div className='row'>
    //             <div className='col-md-3 card'>
    //                 <div className='service-image'>
    //                     <img src={softwareDev} alt={softwareDev} className='img-fluid serv-img'/>
    //                 </div>
    //                 <div className='service-text'>
    //                     <h5>Software Development
    //                     </h5>
    //                     {/* <p>Dive into complex challenges with a top-tier engineering team, crafting innovative software solutions.</p> */}
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>
  )
}

export default OurServices