import React from 'react'
import './../App.css'
import HeaderMenu from '../Components/Header/HeaderMenu'
import Footer from '../Components/Footer/Footer'
import CraftingInnovation from '../Components/Marketplace/CraftingInnovation/CraftingInnovation'
import CraftingCarousal from '../Components/Marketplace/CraftingCarousal/CraftingCarousal'
import SelectCategory from '../Components/Marketplace/SelectCategory/SelectCategory'
import FlashSale from '../Components/Marketplace/FlashSale/FlashSale'
import CategoryOne from '../Components/Marketplace/CategoryOne/CategoryOne'
import CategoryTwo from '../Components/Marketplace/CategoryTwo/CategoryTwo'

function Marketplace() {
  return (
    <div className='body-bg'>
      <HeaderMenu/>
      <CraftingInnovation/>
      <CraftingCarousal/>
      <SelectCategory/>
      <FlashSale/>
      <CategoryOne/>
      <CategoryTwo/>
      <Footer/>
    </div>
  )
}

export default Marketplace