import React from 'react';
import './Provide.css';
import '../../App.css';
import { FaArrowRightLong } from "react-icons/fa6";
import service1 from './../../assets/img/service (1).png';
import service2 from './../../assets/img/service (2).png';
import service3 from './../../assets/img/service (3).png';
import service4 from './../../assets/img/service (4).png';
import { LuBrainCog } from "react-icons/lu";


const Provide = () => {
    return (
        <div className='container-fluid bg-provide py-5'>
            <div className='container'>
                <div className='row py-4'>
                    <div className='col-md-6 d-flex align-items-center'>
                        
                        <button className='btn-design'>What We Provide <FaArrowRightLong /></button>
                    </div>
                    <div className='col-md-6'>
                        <p>Explore diverse areas such as Software Engineering, Design & UX, and more,  to find where your unique talents can shine and drive impact.</p>
                    </div>
                </div>

                {/* carousal */}

                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className='row'>
                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='m-auto card-topper-image'>
                                        <img src={service1} class="img-fluid my-4" alt={service1}/>
                                    </div>
                                    <div className='card-toppper'>
                                        <LuBrainCog className='logo-icon-carousal' />
                                        {/* <img src={}/> */}
                                        <div className='card-topper-text '>
                                            <p className=''>Artificial Intelligence</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='m-auto'>
                                        <img src={service2} class="img-fluid my-4" alt={service2}/>
                                    </div>
                                    <div className='card-toppper'>
                                        <LuBrainCog className='logo-icon-carousal' />
                                        {/* <img src={}/> */}
                                        <div className='card-topper-text'>
                                            <p>Intelligent Automation</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='m-auto'>
                                        <img src={service3} class="img-fluid my-4" alt={service3}/>
                                    </div>
                                    <div className='card-toppper'>
                                        <LuBrainCog className='logo-icon-carousal' />
                                        {/* <img src={}/> */}
                                        <div className='card-topper-text'>
                                            <p>Technology services</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='m-auto'>
                                        <img src={service4} class="img-fluid my-4" alt={service4}/>
                                    </div>
                                    <div className='card-toppper'>
                                        <LuBrainCog className='logo-icon-carousal' />
                                        {/* <img src={}/> */}
                                        <div className='card-topper-text'>
                                            <p>Sales and Commercial</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* <img src="..." className="d-block w-100" alt="..."> */}
                        
                    </div>
                    
                    <div className="carousel-item">
                        <div className='row'>
                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='m-auto card-topper-image'>
                                        <img src={service1} class="img-fluid my-4" alt={service1}/>
                                    </div>
                                    <div className='card-toppper'>
                                        <LuBrainCog className='logo-icon-carousal' />
                                        {/* <img src={}/> */}
                                        <div className='card-topper-text '>
                                            <p className=''>Artificial Intelligence</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='m-auto'>
                                        <img src={service2} class="img-fluid my-4" alt={service2}/>
                                    </div>
                                    <div className='card-toppper'>
                                        <LuBrainCog className='logo-icon-carousal' />
                                        {/* <img src={}/> */}
                                        <div className='card-topper-text'>
                                            <p>Intelligent Automation</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='m-auto'>
                                        <img src={service3} class="img-fluid my-4" alt={service3}/>
                                    </div>
                                    <div className='card-toppper'>
                                        <LuBrainCog className='logo-icon-carousal' />
                                        {/* <img src={}/> */}
                                        <div className='card-topper-text'>
                                            <p>Technology services</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='m-auto'>
                                        <img src={service4} class="img-fluid my-4" alt={service4}/>
                                    </div>
                                    <div className='card-toppper'>
                                        <LuBrainCog className='logo-icon-carousal' />
                                        {/* <img src={}/> */}
                                        <div className='card-topper-text'>
                                            <p>Sales and Commercial</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* <img src="..." className="d-block w-100" alt="..."> */}
                        
                    </div>
                    
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
                </div>         
            </div>
        </div>
    );
};

export default Provide;