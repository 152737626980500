import React from 'react';

import Discover__Service from '../Components/Discover__Service/Discover__Service';
import BrandLogoes from '../Components/BrandLogoes/BrandLogoes';
import Provide from '../Components/Provide/Provide';
import OurServices from '../Components/OurServices/OurServices';
import Challenge from '../Components/Challenge/Challenge';
import OurReviews from '../Components/OurReviews/OurReviews';
import Footer from '../Components/Footer/Footer';
import OurBrand from '../Components/OurBrand/OurBrand';
import HeaderMenu from '../Components/Header/HeaderMenu';

const Home = () => {
    return (
        <div>
            <HeaderMenu/>
            <Discover__Service/>
            <BrandLogoes/>
            <Provide/>
            <OurServices/>
            <OurBrand/>
            <Challenge/>
            {/* <OurReviews/> */}
            <Footer/>
        </div>
    );
};

export default Home;