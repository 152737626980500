import React from 'react';
import Logo from './../../assets/img/logo.png';
import { CiSearch } from "react-icons/ci";
import { TbWorld } from "react-icons/tb";
import { FaAngleDown } from "react-icons/fa";
import './HeaderMenu.css';

function HeaderMenu() {
  return (
    <div className='navbar-area sticky-top navbar-light bg-light'>
        <div className='container'>
        <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={Logo} style={{"width":"40px"}}/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                            
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Who We Are
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a class="dropdown-item" href="#">About Us</a></li>
                                    <li><a class="dropdown-item" href="#">Our Products</a></li>
                                    <li><a class="dropdown-item" href="#">Our Teams</a></li>
                                </ul>
                            </li> */}

                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    What We Do
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    
                                </ul>
                            </li> */}

                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Our Impact
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    
                                </ul>
                            </li> */}

                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">Career</a>
                            </li> */}

                            <li className="nav-item">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            
                            <li className="nav-item">
                                <a className="nav-link" href="/marketplace">Marketplace</a>
                            </li>
                            
                            <li className="nav-item">
                                <a className="nav-link" href="/contact-us">Contact Us</a>
                            </li>
                        </ul>
                    
                        <div class="dropdown">
                            <button class="dropdown-toggle border-0 bg-white" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <TbWorld /> ENG
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Spanish</a></li>
                                <li><a class="dropdown-item" href="#">Italian</a></li>
                                <li><a class="dropdown-item" href="#">French</a></li>
                            </ul>
                        </div>

                    </div>
            </div>
        </nav>
    </div>
    </div>
    
  )
}

export default HeaderMenu