import React from 'react';
import './CraftingCarousal.css';
import newOffer from './../../../assets/web-commerce-img/new-offer.png';
import carusalImg from './../../../assets/web-commerce-img/carousal-image-group.png';

function CraftingCarousal() {
  return (
    <div className='container py-4'>
        <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item carousal-bg active" data-bs-interval="10000">
                    <div className='carousal-left-part px-5 py-5'>
                        <div className='carousal-left-top-part-text'>
                            <div className='carousal-left-text'>
                                <p className='carousal-text-get'>Get 30%</p>
                                <span className='text-danger carousal-text-off'>OFF</span>
                            </div>
                            <div className='carousal-left-img'>
                                <img src={newOffer} alt={newOffer} className='img-fluid crafting-new-offer-img'/>
                            </div>
                        </div>
                        <div className='carousal-left-top-part-text'>
                            <p className='carousal-left-top-part-text-p'>On your first purchase</p>
                        </div>
                    </div>
                    <div className='carousal-right-part p-5'>
                        <img src={carusalImg} alt={carusalImg} className='img-fluid carousal-right-part-img'/>
                    </div>
                </div>

                <div class="carousel-item carousal-bg" data-bs-interval="2000">
                    <div className='carousal-left-part px-5 py-5'>
                        <div className='carousal-left-top-part-text'>
                            <div className='carousal-left-text'>
                                <p className='carousal-text-get'>Get 30%</p>
                                <span className='text-danger carousal-text-off'>OFF</span>
                            </div>
                            <div className='carousal-left-img'>
                                <img src={newOffer} alt={newOffer} className='img-fluid crafting-new-offer-img'/>
                            </div>
                        </div>
                        <div className='carousal-left-top-part-text'>
                            <p>On your first purchase</p>
                        </div>
                    </div>
                    <div className='carousal-right-part p-5'>
                        <img src={carusalImg} alt={carusalImg} className='img-fluid carousal-right-part-img'/>
                    </div>
                </div>

                <div class="carousel-item carousal-bg">
                    <div className='carousal-left-part p-5'>
                        <div className='carousal-left-top-part-text'>
                            <div className='carousal-left-text'>
                                <p className='carousal-text-get'>Get 30%</p>
                                <span className='text-danger carousal-text-off'>OFF</span>
                            </div>
                            <div className='carousal-left-img'>
                                <img src={newOffer} alt={newOffer} className='img-fluid crafting-new-offer-img'/>
                            </div>
                        </div>
                        <div className='carousal-left-top-part-text'>
                            <p>On your first purchase</p>
                        </div>
                    </div>
                    <div className='carousal-right-part p-5'>
                        <img src={carusalImg} alt={carusalImg} className='img-fluid carousal-right-part-img'/>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
  )
}

export default CraftingCarousal