import React from 'react'
import './Footer.css';
import logoBottom from './../../assets/img/logo.png';
import TermsPolicy from '../TermPolicy/TermsPolicy';
import telegram from './../../assets/img/telegram.png'
import whatsapp from './../../assets/img/whatsapp.png'
import email from './../../assets/img/email.png'

const email_url = process.env.REACT_APP_EMAIL;
const telegram_url = process.env.REACT_APP_TELEGRAM;
const whatsapp_url = process.env.REACT_APP_WHATSAPP;
// console.log("Url is", whatsapp_url);



function Footer() {
    
   
  return (
    

    <div className='container-fluid bg-footer'>
        <div className='container py-5'>
            <div className='row'>
                <div className='col-md-8'>
                    <img src={logoBottom} alt={logoBottom} className='logo-bottom'/>
                    <div className=''>
                        {/* <MainMenu/> */}
                        <div className='d-flex ms-4'>
                            <a className='text-white text-decoration-none' href={email_url}><img src={email} style={{"width":"30px"}}/></a>
                            <a className='text-white text-decoration-none' href={whatsapp_url} target="_blank"><img src={whatsapp} style={{"width":"60px", "margin-top":"-14px"}}/></a>
                            {/* <CustomWhatsAppLink/> */}
                            <a className='text-white text-decoration-none' href={telegram_url} target="_blank"><img src={telegram} style={{"width":"30px"}}/></a>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <p className='d-block footer-lower-news-text'>Get the freshest news from us</p>
                    
                    <div className='row'>
                        <div className='col-md-8'>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                        </div>
                        <div className='col-md-4'>
                            <button type="submit" class="btn-design btn-subscribe">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <hr className='text-white m-0 py-2'/>
        <div className='container'>
            <TermsPolicy/>
        </div>
    </div>
  )
}

export default Footer