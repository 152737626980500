import React from 'react';
import './Contactus.css';
import telegram from './../../assets/img/telegram.png'
import whatsapp from './../../assets/img/whatsapp.png'
import email from './../../assets/img/email.png'
import { FaWhatsapp } from "react-icons/fa";
import { RiTelegramLine } from "react-icons/ri";
import { MdOutlineEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { TbHours24 } from "react-icons/tb";



// const email_url = process.env.REACT_APP_EMAIL;
const telephone_url = process.env.REACT_APP_TELEPHONE;
const telegram_url = process.env.REACT_APP_TELEGRAM;
const whatsapp_url = process.env.REACT_APP_WHATSAPP;
const fb_url = process.env.REACT_APP_FACEBOOK;
const email_url = process.env.REACT_APP_EMAIL;

// console.log("Url is", whatsapp_url);

function Contactus() {
  return (
    <div className='pb-4'>
      {/* contact Contact Us area */}
      <div className='contact py-3'>
          <div className='container text-center'>
              <p className='contact-head-text'>Contact Us</p>
              <p>At The Second Source, we’re here to make your life easy. Whether you have questions about our services, need support, or want to explore collaboration opportunities, we’d love to hear from you.</p>
          </div>
      </div>
      {/* contact Contact Us area */}

      {/* contact Get in touch area */}
      <div className='getin-touch py-2'>
            <div className='container text-center'>
                <p className='getin-touch-head-text'>Get in Touch</p>
                <p>Feel free to reach out through any of the following channels:</p>
            </div>
      </div>
      {/* contact Get in touch area */}

      <div className='container'>
          <div className='row'>
              <div className='col-md-6'>
                  <div className='contact-card-design h-100'>
                      <h4>Contact</h4>
                      <a href={email_url} target="_blank" className='text-decoration-none text-dark'><p><MdOutlineEmail className='me-2'/> Email: contact@2ndsource.xyz</p></a>
                      <a href={telephone_url} target="_blank" className='text-decoration-none text-dark'><p><FaPhone className='me-2'/>
                      Phone: +880 1929-988183</p></a>
                      <a href={whatsapp_url} target="_blank" className='text-decoration-none text-dark'><p> <FaWhatsapp className='me-2'/> Whatsapp: +880 1929-988183</p></a>
                      <a href={telegram_url} target="_blank" className='text-decoration-none text-dark'><p><RiTelegramLine className='me-2'/> Telegram: +880 1929-988183</p></a>
                      <p><TbHours24 className='me-2'/>
                      Hours: 24/7</p>
                  </div>
              </div>
              <div className='col-md-6'>
                  <div className='contact-card-design text-end h-100'>
                      <h4>Support</h4>
                      <p>Facing issues or need assistance with our services or products?</p>
                      <p><MdOutlineEmail className='me-2'/> Email: support@2ndsource.xyz</p>
                      <p><TbHours24 className='me-2'/>
                      Hours: 24/7</p>
                  </div>
              </div>
          </div>
      </div>



        {/* <div className='container-fluid text-center fw-bol'>
          
          <div className='container'>
            <div className='contact-text mt-4'>
                <p className='connect-text'>Let’s Connect</p>
            </div>
            <div className='contact-area'>
              <div className='contact-card-bg'>
              <a className='text-decoration-none text-dark px-2' href={email_url} target="_blank"> <img src={email} style={{"width":"45px"}}/> </a>
                  <p>Email: contact@2ndsource.xyz</p>
                  <a className='fb-button' href={fb_url} target="_blank"><i class="fa-brands fa-facebook-f"></i> Facebook</a>
              </div>
              <div className='contact-card-bg'>
                  <a className='text-decoration-none px-2 whatsapp-button' href={whatsapp_url} target="_blank"> 
                    Whatsapp
                    <img src={whatsapp} style={{"width":"80px", "margin-top":"-18px"}}/> 
                    </a>
              </div>
              <div className='contact-card-bg'>
                  <a className='text-decoration-none px-2 telegram-button' href={telegram_url} target="_blank">
                    <img src={telegram} style={{"width":"45px"}}/>
                    Telegram
                    </a>
              </div>
            </div>
          </div>
      </div> */}
    </div>
  )
}

export default Contactus