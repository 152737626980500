import React from 'react';
import './CategoryOne.css';
// import lento from './../../../assets/web-commerce-img/lento.png';
// import potential from './../../../assets/web-commerce-img/potential.png';
// import changeRed from './../../../assets/web-commerce-img/changered.png';
// import weare from './../../../assets/web-commerce-img/weare.png';
// import connect from './../../../assets/web-commerce-img/connect.png';
import save from './../../../assets/web-commerce-img/save.png';

import img1 from './../../../assets/our-images/1.png'
import img2 from './../../../assets/our-images/2.png'
import img3 from './../../../assets/our-images/3.png'
import img4 from './../../../assets/our-images/4.png'
import img5 from './../../../assets/our-images/5.png'
import img6 from './../../../assets/our-images/6.png'



function CategoryOne() {
  return (
    <div className='container'>
        <div className='category-one-heading-text-area text-center'>
            <p className='category-one-heading-text'>E-Commerce</p>
            <p className='category-one-heading-text-bottom'>Chose the best website for your works</p>
        </div>
        <div className='row py-4'>
            <div className='col-md-4 py-2'>
                <div className=''>
                    <div className='card-img'>
                        <img src={img1} alt={img1} className="img-fluid h-100"/>
                    </div>
                    <div className='card-img-bottom-text'>
                        <div className='card-bottom-text'>
                            <p>Landing Page</p>
                        </div>
                        <div className='card-bottom-text'>
                            {/* <p><img src={save} alt={save}/> $ 29</p> */}
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4  py-2'>
                <div className=''>
                    <div className='card-img'>
                        <img src={img2} alt={img2} className="img-fluid h-100"/>
                    </div>
                    <div className='card-img-bottom-text'>
                        <div className='card-bottom-text'>
                            <p>Service</p>
                        </div>
                        <div className='card-bottom-text'>
                            {/* <p><img src={save} alt={save}/> $ 29</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4  py-2'>
                <div className=''>
                    <div className='card-img'>
                        <img src={img3} alt={img3} className="img-fluid h-100"/>
                    </div>
                    <div className='card-img-bottom-text'>
                        <div className='card-bottom-text'>
                            <p>Small Business</p>
                        </div>
                        <div className='card-bottom-text'>
                            {/* <p><img src={save} alt={save}/> $ 29</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4  py-2'>
                <div className=''>
                    <div className='card-img'>
                        <img src={img4} alt={img4} className="img-fluid h-100"/>
                    </div>
                    <div className='card-img-bottom-text'>
                        <div className='card-bottom-text'>
                            <p>Landing Page</p>
                        </div>
                        <div className='card-bottom-text'>
                            {/* <p><img src={save} alt={save}/> $ 29</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4  py-2'>
                <div className=''>
                    <div className='card-img'>
                        <img src={img5} alt={img5} className="img-fluid h-100"/>
                    </div>
                    <div className='card-img-bottom-text'>
                        <div className='card-bottom-text'>
                            <p>Hotel Booking</p>
                        </div>
                        <div className='card-bottom-text'>
                            {/* <p><img src={save} alt={save}/> $ 29</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4  py-2'>
                <div className=''>
                    <div className='card-img'>
                        <img src={img6} alt={img6} className="img-fluid h-100"/>
                    </div>
                    <div className='card-img-bottom-text'>
                        <div className='card-bottom-text'>
                            <p>E-commerce</p>
                        </div>
                        <div className='card-bottom-text'>
                            {/* <p><img src={save} alt={save}/> $ 29</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CategoryOne