import React from 'react'
import HeaderMenu from '../Components/Header/HeaderMenu'
import Footer from '../Components/Footer/Footer'
import Contactus from '../Components/Contactus/Contactus'

function Contact() {
  return (
    <div>
        <HeaderMenu/>
        <Contactus/>
        <Footer/>
    </div>
  )
}

export default Contact