import React from 'react';
import './Discover__Service.css';
import Frame5041 from './../../assets/img/Frame5041.png';
// import { Button } from 'react-bootstrap';
import './../../App.css';
import { FaArrowRightLong } from "react-icons/fa6";

const Discover__Service = () => {
    return (
        <div className='container-fluid discover__top__gap discover__bg'>
            <div className='container'>
                <div className='row d-flex align-items-center'>
                    <div className='col-md-6'>
                        <h1 className='py-4'>We Make Your Life Easy
                        with 360° Services</h1>
                        <button className='btn-design d-flex align-items-center px-4'>Discover Our Service <FaArrowRightLong className='mx-1'/></button>
                        
                    </div>
                    <div className='col-md-6'>
                        <img className='img-fluid' src={Frame5041} alt={Frame5041}/>
                        <p className='py-4'>At The Second Source, we specialize in empowering businesses with innovative solutions, advanced technology, and unmatched expertise. From streamlining operations to delivering tailored strategies, we make it easy for you to achieve your goals and stay ahead of the competition.</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Discover__Service;