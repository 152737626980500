import React from 'react';
import './CraftingInnovation.css';
import { FaArrowRightLong } from "react-icons/fa6";
import laptop from './../../../assets/web-commerce-img/laptop.png';

function CraftingInnovation() {
  return (
    <div className='container-fluid craftingInnovation_area py-4'>
        <div className='container py-4 craft-bg'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='pt-3'>
                        <p className='crafting-text'>At Second Source</p>
                        <h2 className='crafting-h2'>"Crafting Innovation, Designing Connections, Building Brands."</h2>
                        <button className='crafting-button my-4'>Get Help <FaArrowRightLong className='mx-1'/></button>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='crafting-content-left'>
                        <p className='shape-text-p'>"Shape Your Future</p>
                        <p>
                            <span className='shape-text-p-with-the'>with the <br/></span>
                            <span className='shape-text-h2'>Next <br/> Big Thing."</span>
                        </p>
                    </div>
                    <div className='crafting-content-right'>
                        <img src={laptop} className='img-fluid'/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CraftingInnovation