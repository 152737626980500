import React from 'react';
import './FlashSale.css'
import { FaArrowRight } from "react-icons/fa";
import Computer from './../../../assets/web-commerce-img/computer.png';
import Save from './../../../assets/web-commerce-img/save.png';

function FlashSale() {
  return (
    <div className='container-fluid'>
        <div className='container'>
            <div className='d-flex align-items-center'>
                <div className='flash-sale float-left'>
                    <p className='flash-sale-text align-items-center justify-content-center m-auto'>Flash Sale</p>
                </div>
                <div className='endin-message px-2'>
                    <div className='endin-p d-flex align-items-center justify-content-center'>
                        <p className='endin-text'>Ends in</p>
                        <p className='endin-text'>11:51:00</p>
                    </div>
                </div>
                <div>
                    <p className='flash-sale-gate-view-text-gate-before m-0 px-2'>Gate before offer ends!</p>
                </div>
                <div>
                <p className='flash-sale-gate-view-text m-0 px-2 d-flex align-items-center text-primary'>View More <FaArrowRight className='mx-2'/></p>
                </div>
            </div>

            {/* Flash Sale area  */}

            <div className='row py-4'>
                <div className='col-md-2 card-area'>
                    <div className='img-area'>
                        <img src={Computer} alt={Computer} className="img-fluid w-full"/>
                    </div>
                    <div className='flash-sale-img-hover-text'>
                        <div className='flash-sale-hover-card-icon'>
                            <img src={Save}/>
                        </div>
                        
                        <div className='d-flex align-items-center flash-bottom-text'>
                            <div className='col-md-6'>
                                <p className='dr-portfolio'>Dr. Portfolio</p>
                            </div>
                            <div className='col-md-6'>
                                <p className='float-end price-portfolio flash-price-updated'><del className='text-white'>$99</del> <span className='flash-price'>$25</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 card-area'>
                    <div className='img-area'>
                        <img src={Computer} alt={Computer} className="img-fluid w-full"/>
                    </div>
                    <div className='flash-sale-img-hover-text'>
                        <div className='flash-sale-hover-card-icon'>
                            <img src={Save} alt={Save}/>
                        </div>
                        
                        <div className='d-flex align-items-center flash-bottom-text'>
                            <div className='col-md-6'>
                                <p className='dr-portfolio'>Dr. Portfolio</p>
                            </div>
                            <div className='col-md-6'>
                                <p className='float-end price-portfolio flash-price-updated'><del className='text-white'>$99</del> <span className='flash-price'>$25</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 card-area'>
                    <div className='img-area'>
                        <img src={Computer} alt={Computer} className="img-fluid w-full"/>
                    </div>
                    <div className='flash-sale-img-hover-text'>
                        <div className='flash-sale-hover-card-icon'>
                            <img src={Save}/>
                        </div>
                        
                        <div className='d-flex align-items-center flash-bottom-text'>
                            <div className='col-md-6'>
                                <p className='dr-portfolio'>Dr. Portfolio</p>
                            </div>
                            <div className='col-md-6'>
                                <p className='float-end price-portfolio flash-price-updated'><del className='text-white'>$99</del> <span className='flash-price'>$25</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 card-area'>
                    <div className='img-area'>
                        <img src={Computer} alt={Computer} className="img-fluid w-full"/>
                    </div>
                    <div className='flash-sale-img-hover-text'>
                        <div className='flash-sale-hover-card-icon'>
                            <img src={Save}/>
                        </div>
                        
                        <div className='d-flex align-items-center flash-bottom-text'>
                            <div className='col-md-6'>
                                <p className='dr-portfolio'>Dr. Portfolio</p>
                            </div>
                            <div className='col-md-6'>
                                <p className='float-end price-portfolio flash-price-updated'><del className='text-white'>$99</del> <span className='flash-price'>$25</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 card-area'>
                    <div className='img-area'>
                        <img src={Computer} alt={Computer} className="img-fluid w-full"/>
                    </div>
                    <div className='flash-sale-img-hover-text'>
                        <div className='flash-sale-hover-card-icon'>
                            <img src={Save}/>
                        </div>
                        
                        <div className='d-flex align-items-center flash-bottom-text'>
                            <div className='col-md-6'>
                                <p className='dr-portfolio'>Dr. Portfolio</p>
                            </div>
                            <div className='col-md-6'>
                                <p className='float-end price-portfolio flash-price-updated'><del className='text-white'>$99</del> <span className='flash-price'>$25</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 card-area'>
                    <div className='img-area'>
                        <img src={Computer} alt={Computer} className="img-fluid w-full"/>
                    </div>
                    <div className='flash-sale-img-hover-text'>
                        <div className='flash-sale-hover-card-icon'>
                            <img src={Save}/>
                        </div>
                        
                        <div className='d-flex align-items-center flash-bottom-text'>
                            <div className='col-md-6'>
                                <p className='dr-portfolio'>Dr. Portfolio</p>
                            </div>
                            <div className='col-md-6'>
                                <p className='float-end price-portfolio flash-price-updated'><del className='text-white'>$99</del> <span className='flash-price'>$25</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>









        </div>
    </div>
  )
}

export default FlashSale