import {Routes, Route} from 'react-router-dom';

import './App.css';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home';
import Marketplace from './Pages/Marketplace.js'; 
import Contact from './Pages/Contact.js';

function App() {
  return (
    <div>
        <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/marketplace' element={<Marketplace/>}></Route>
            <Route path='/contact-us' element={<Contact/>}></Route>
            
        </Routes>
        
    </div>
  );
}

export default App;
